import React from "react"
import'bootstrap/dist/css/bootstrap.min.css';
import {Navbar,Nav} from "react-bootstrap";
function myNavbar(){
    return (
<Navbar activeKey="1" sticky="top" bg="dark" variant="dark" expand="lg" id = "gradient-color">
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="justify-content-end" style={{ width: "100%" }}>
      <Nav.Link href="../../../" style={{padding:"16px", fontSize:"20px"}}>Home</Nav.Link>
      <Nav.Link href="../../../projects" style={{padding:"16px", fontSize:"20px"}}>Projects</Nav.Link>
      {/* <Nav.Link href="../../../blog" style={{padding:"16px", fontSize:"20px"}}>Blog</Nav.Link> */}
      <Nav.Link href="../../../resume.pdf" style={{padding:"16px", fontSize:"20px"}}>Resume</Nav.Link>
      {/* <Nav.Link href="../../../contact" style = {{paddingLeft: "16px", paddingTop:"16px", fontSize:"20px"}}>Contact Me</Nav.Link> */}
     
    </Nav>
    
  </Navbar.Collapse>
</Navbar>
          )
}

export default myNavbar