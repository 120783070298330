import React from "react"
import { FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';
function Footer(){
    return (
        <div className="skills-dark-background font-color-white p-32" style={{width:"100%"}}>
            <p className="text-center"> Want to connect? Send me an email at anerish3@gmail.com. Or connect with me on</p>
            <div className="row" style={{justifyContent:"space-evenly"}}>
                <a href="https://twitter.com/aneri_sh" style={{textDecoration:"none"}}><FaTwitter size="32px" title="Twitter" color="white"/></a>
                <a href="https://github.com/anerishah97" style={{textDecoration:"none"}}><FaGithub size="32px" title="Github" color="white"/></a>
                <a href="https://www.linkedin.com/in/anerishah97/" style={{textDecoration:"none"}}><FaLinkedin size="32px" title="Linkedin" color="white"/></a>
            </div>
        </div>
    )
}

export default Footer